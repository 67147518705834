<template>
  <div class="platform">
    <ul>
      <li>
        <div class="header">
          <div class="pic">
            <img src="../../assets/image/product/platformicon.png" alt="">
          </div>
          <p>智能物联</p>
        </div>
        <div class="info">多源数据的接入及运用，可使平台数据多元化、功能多样化。结合智能建造管理平台更加有效掌握现场进度，了解实际情况，为施工监管和事件决策提供了...</div>
      </li>
       <li>
        <div class="header">
          <div class="pic">
            <img src="../../assets/image/product/platformicon.png" alt="">
          </div>
          <p>智慧工地</p>
        </div>
        <div class="info">智慧工地基于Galaxy智能建造管理平台与现场多个子系统的互联，实现现场各类工况数据采集、存储、分析与应用。通过接入智慧工地物联网云平台的多...</div>
      </li>
      <li>
        <div class="header">
          <div class="pic">
            <img src="../../assets/image/product/platformicon.png" alt="">
          </div>
          <p>质量验收</p>
        </div>
        <div class="info">质量验收是工程建设质量管理的闭合环节，从发现问题开始，至整改、验收、闭合后，对问题进行追踪，用户可随时查看状态，了解情况。</div>
      </li>
       <li>
        <div class="header">
          <div class="pic">
            <img src="../../assets/image/product/platformicon.png" alt="">
          </div>
          <p>三维联动</p>
        </div>
        <div class="info">平台将上传数据进行分类编码，数据与数据之间互相绑定，使得数据联动，信息共享。</div>
      </li>
      <li>
        <div class="header">
          <div class="pic">
            <img src="../../assets/image/product/platformicon.png" alt="">
          </div>
          <p>权限分配</p>
        </div>
        <div class="info">权限分配及用户管理是管理平台中不可缺少的一部分，平台用户很多，平台功能也很多，不同职能用户使用平台的功能权限不同，出于安全等因素考虑，...</div>
      </li>
       <li>
        <div class="header">
          <div class="pic">
            <img src="../../assets/image/product/platformicon.png" alt="">
          </div>
          <p>Galaxy 智能建造管理平台</p>
        </div>
        <div class="info">Galaxy智能建造管理平台 （以下简称平台）是以BlackHole三维实时渲染引擎为基础，结合分布式计算、物联网等技术，为工程建设管理人员提供的项...</div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
*{
  box-sizing: border-box;
}
.platform{
  width: 100%;
  ul{
    max-width: 1200px;
    margin: 0px auto 146px;
    // background-color: pink;
    overflow: hidden;
    padding: 10px;
    li{
      width: 31%;
      // background-color: blue;
      height: 170px;
      margin-right: 3%;
      float: left;
      margin-top: 80px;
      padding: 10px;
      border-radius:3px;
      box-shadow: -1px 0 10px rgba(0, 0, 0, 0.05), 0 -1px 10px rgba(0, 0, 0, 0.05), 0 1px 10px rgba(0, 0, 0, 0.05), 1px 0 10px rgba(0, 0, 0, 0.05);
      .header{
        overflow: hidden;
        .pic{
          width: 68px;
          height: 68px;
          float: left;
          border: 1px dashed #ccc;
          img{
            width: 100%;
            height: 100%;
          }
        }
        p{
          float: left;
          line-height: 68px;
          margin-left: 10px;
          display: block;
          width: calc(100% - 78px);
          // word-wrap: break-word;
          // word-break: break-all;
          // overflow: hidden;
        }
      }
      .info{
        margin-top: 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }
    }
    li:nth-child(3n+3){
      margin-right: 0%;
    }
  }
}
</style>
